import React from "react";
import { Typography, Grid, Hidden, IconButton } from "@material-ui/core";
import { ContentfulFooter, ContentfulNavigationItem } from "types/graphql-types";
import styled from "styled-components";
import theme from "src/constants/theme";
import MobileLinks from "./MobileLinks";
import Language from "../../language";
import { Link } from "gatsby-plugin-intl";

import SocialIcon from "./SocialIcon";
import ContentWidthWrapper from "../components/ContentWidthWrapper";

export type LinkEntry = Pick<
  ContentfulNavigationItem,
  "id" | "label" | "url" | "type"
>;

const Footer = ({ contentfulFooter }:
  {
    contentfulFooter: ContentfulFooter,
  }) => {
  return (
    <FooterStyled>
      <ContentWidthWrapper>
        <Grid container>
          <Grid item xs={12} md={5}>
            <LeftSide item md={11}>
              <Typography variant="h6" component="p" gutterBottom>
                {contentfulFooter?.title}
              </Typography>
              <TextStyled variant="body2" gutterBottom>
                {contentfulFooter?.text}
              </TextStyled>
              <Hidden smDown>
                <Copyright variant="body2">
                  {contentfulFooter?.copyright}
                </Copyright>
              </Hidden>
            </LeftSide>
          </Grid>
          <Hidden smDown>
            <RightSideDesktop
              item
              md={7}
              direction="row"
              container
              justify="space-between"
            >
              <GridItem item md={3}>
                <Typography variant="h5" gutterBottom>
                  {contentfulFooter?.columnOneTitle}
                </Typography>
                {renderLinks(contentfulFooter!.columnOneLinks! as LinkEntry[])}
              </GridItem>
              <GridItem item md={4}>
                <Typography variant="h5" gutterBottom>
                  {contentfulFooter?.columnTwoTitle}
                </Typography>
                {renderLinks(contentfulFooter!.columnTwoLinks! as LinkEntry[])}
              </GridItem>
              <GridItem item md={3}>
                <Typography variant="h5" gutterBottom>
                  {contentfulFooter?.columnThreeTitle}
                </Typography>
                {renderLinks(
                  contentfulFooter!.columnThreeLinks! as LinkEntry[]
                )}
              </GridItem>
              <GridItem item md={1} container direction="column">
                {contentfulFooter?.instagramLink && (
                  <SocialLink
                    target="_blank"
                    href={contentfulFooter?.instagramLink}
                    disableRipple
                  >
                    <SocialIcon type="instagram" />
                  </SocialLink>
                )}
                {contentfulFooter?.facebookLink && (
                  <SocialLink
                    target="_blank"
                    href={contentfulFooter?.facebookLink}
                    disableRipple
                  >
                    <SocialIcon type="facebook" />
                  </SocialLink>
                )}
                {contentfulFooter?.pinterestLink && (
                  <SocialLink
                    target="_blank"
                    href={contentfulFooter?.pinterestLink}
                    disableRipple
                  >
                    <SocialIcon type="pinterest" />
                  </SocialLink>
                )}
              </GridItem>
              <GridItem item md={6}>
                <Typography variant="h5" gutterBottom>
                  {contentfulFooter?.columnFourTitle}
                </Typography>
                <Language
                  contentfulFooter={contentfulFooter}
                />
              </GridItem>
            </RightSideDesktop>
          </Hidden>
          <Hidden mdUp>
            <MobileLinks content={contentfulFooter} renderLinks={renderLinks} />
          </Hidden>
        </Grid>
      </ContentWidthWrapper>
    </FooterStyled>
  );

  function renderLinks(links: LinkEntry[]) {
    return links.map((link) =>
      link?.type === "internal" ? (
        <InternLink to={link.url!} key={link.id}>
          <TextStyled variant="body2">{link?.label}</TextStyled>
        </InternLink>
      ) : (
        <ExternLink
          key={link.id}
          href={link.url!}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TextStyled variant="body2">{link?.label}</TextStyled>
        </ExternLink>
      )
    );
  }
};

export default Footer;

const SocialLink = styled(IconButton) <{ href: string; target: string }>`
  padding: ${theme.spacing(1)}px;
  margin-top: -${theme.spacing(1)}px;
`;

const FooterStyled = styled.div`
  padding: ${theme.spacing(4, 3)};
  flex-shrink: 1;
  background-color: ${theme.palette.sandal.main};
  ${theme.breakpoints.up("md")} {
    padding: ${theme.spacing(5, 5, 5, 12)};
  }
  ${theme.breakpoints.up("lg")} {
    padding: ${theme.spacing(5, 6)};
  }
`;

const LeftSide = styled(Grid)`
  padding-right: ${theme.spacing(5)}px;
  padding-bottom: ${theme.spacing(1)}px;
  margin-bottom: ${theme.spacing(2)}px;
  border-bottom: 1px solid #979797;
  ${theme.breakpoints.up("md")} {
    border-right: 1px solid #979797;
    margin-bottom: 0;
    border-bottom: none;
  }
`;
const TextStyled = styled(Typography)`
  line-height: 2;
`;

const InternLink = styled(Link)`
  text-decoration: none;
  color: ${theme.palette.black.main};
  &:visited {
    color: ${theme.palette.black.main};
  }
  &:hover {
    color: ${theme.palette.black.main} !important;
  }
`;
const ExternLink = styled.a`
  text-decoration: none;
  color: ${theme.palette.black.main};
  &:visited {
    color: ${theme.palette.black.main};
  }
  &:active {
    color: ${theme.palette.black.main};
  }
  &:hover {
    color: ${theme.palette.black.main} !important;
  }
`;

const Copyright = styled(Typography)`
  font-size: 10px;
  font-weight: 500;
`;

const RightSideDesktop = styled(Grid)`
  width: auto;
  flex-grow: 1;
`;

const GridItem = styled(Grid)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(3)}px;
  border-bottom: 1px solid #979797;
  ${theme.breakpoints.up("md")} {
    margin-bottom: ${theme.spacing(5)}px;
    border-bottom: none;
  }
  :last-of-type {
    margin-right: ${theme.spacing(5)}px;
  }
`
);
