import React from "react";

import { Pinterest } from "@material-ui/icons";

interface Props {
  type: "instagram" | "facebook" | "pinterest";
}

type IconProps = {
  big?: boolean;
};

const Instagram = ({ big }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={big ? 32 : 24}
    height={big ? 32 : 24}
    viewBox="0 0 24 24"
  >
    <path
      fill="#3C3C3C"
      fillRule="evenodd"
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm.62 6H12c-.828 0-1.648-.007-2.476.04-.961.046-1.813.265-2.516.968-.703.703-.922 1.555-.968 2.516-.036.621-.04 1.238-.04 1.857V12c0 .828-.007 1.648.04 2.476.046.961.265 1.813.968 2.516.703.703 1.555.922 2.516.968.621.036 1.238.04 1.857.04H12c.828 0 1.648.007 2.476-.04.961-.046 1.813-.265 2.516-.968.703-.703.922-1.555.968-2.516.036-.621.04-1.238.04-1.857V12c0-.828.007-1.648-.04-2.476-.046-.961-.265-1.813-.968-2.516-.703-.703-1.555-.922-2.516-.968A32.758 32.758 0 0 0 12.62 6zm2.92 1.32c.273.11.476.243.687.454.211.21.344.414.453.687.313.79.242 2.664.242 3.54 0 .874.07 2.75-.242 3.539-.11.273-.242.476-.453.687-.21.211-.414.344-.687.453-.79.313-2.665.242-3.54.242s-2.75.07-3.539-.242a1.918 1.918 0 0 1-.687-.453 1.918 1.918 0 0 1-.453-.687c-.313-.79-.242-2.665-.242-3.54s-.07-2.75.242-3.539c.11-.273.242-.476.453-.687.21-.211.414-.344.687-.453.79-.313 2.664-.242 3.54-.242.874 0 2.75-.07 3.539.242zM12 8.923A3.074 3.074 0 0 0 8.922 12 3.074 3.074 0 0 0 12 15.08 3.074 3.074 0 0 0 15.08 12 3.074 3.074 0 0 0 12 8.922zM12 10c1.102 0 2 .899 2 2 0 1.102-.898 2-2 2-1.101 0-2-.898-2-2 0-1.101.899-2 2-2zm3.204-1.921a.717.717 0 0 0-.72.718c0 .399.321.72.72.72.398 0 .718-.321.718-.72a.717.717 0 0 0-.718-.718z"
    />
  </svg>
);

const Facebook = ({ big }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={big ? 32 : 24}
    height={big ? 32 : 24}
    viewBox="0 0 24 24"
  >
    <path
      fill="#3C3C3C"
      fillRule="evenodd"
      d="M14.02 12h-1.3v4.69h-1.94V12h-.97v-1.62h.97v-.97c0-1.31.55-2.1 2.1-2.1h1.3v1.62h-.81c-.61 0-.65.22-.65.64v.81h1.47L14.02 12zM12 0C5.37 0 0 5.37 0 12s5.37 12 12 12 12-5.37 12-12S18.63 0 12 0z"
    />
  </svg>
);

const SocialIcon = ({ type, big }: Props & IconProps) => {
  if (type === "facebook") return <Facebook big={big} />;
  if (type === "instagram") return <Instagram big={big} />;
  if (type === "pinterest")
    return <Pinterest style={{ fontSize: big ? 32 : 27, color: "#3C3C3C" }} />;
  return <Instagram big={big} />;
};

export default SocialIcon;
