import React, { useState, FC } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Hidden } from "@material-ui/core";
import styled from "styled-components";
import NavLink from "./NavLink";
import { LinkEntry } from ".";
import BrandLogo from "./BrandLogo";

interface Props {
  links: LinkEntry;
}

const MobileNavigation: FC<Props> = ({ links }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Hidden mdUp>
        <BrandLogo />
      </Hidden>
      {!showMenu ? (
        <BurgerMenu onClick={() => setShowMenu(!showMenu)}>
          <MenuIcon />
        </BurgerMenu>
      ) : (
        <BurgerMenu onClick={() => setShowMenu(!showMenu)}>
          <CloseIcon />
        </BurgerMenu>
      )}

      {showMenu && (
        <Navigation>
          <Content>
            <BrandLogo />
            <BurgerMenu onClick={() => setShowMenu(!showMenu)}>
              <CloseIcon />
            </BurgerMenu>

            <NavigationContainer onClick={handleMenuClose}>
              {links!.map((link) => (
                <NavLink
                  key={link!.id}
                  to={link!.url!}
                  partiallyActive={true}
                  activeClassName="activeClassName"
                >
                  {link!.label}
                </NavLink>
              ))}
            </NavigationContainer>
          </Content>
        </Navigation>
      )}
    </>
  );

  function handleMenuClose() {
    setShowMenu(false);
  }
};

export default MobileNavigation;

const Content = styled("div")`
  position: relative;
  padding: 16px 24px;
`;

const NavigationContainer = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 32px;
`;

const BurgerMenu = styled(IconButton)(
  ({ theme }) => `
  display: flex;
  margin: -12px -12px 0 0;
  position: absolute;
  top: 16px;
  right: 32px;
  ${theme.breakpoints.up("md")} {
    display: none;
  }
`
);

const Navigation = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.palette.white.main};
`;
