import React, { FC } from "react";
import styled from "styled-components";

import NavLink from "./NavLink";
import BrandLogo from "./BrandLogo";

import { LinkEntry } from ".";

const Root = styled("div")(
  ({ theme }) => `
display: block;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
${theme.breakpoints.down("sm")} {
  display: none;
}
`
);

interface Props {
  links: LinkEntry;
}

const Navigation: FC<Props> = ({ links }) => (
  <Root>
    <BrandLogo />

    <nav>
      {links!.map((link) => (
        <NavLink
          key={link!.id}
          to={link!.url!}
          partiallyActive={true}
          activeClassName="active"
        >
          {link!.label}
        </NavLink>
      ))}
    </nav>
  </Root>
);

export default Navigation;
