import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

const ContentWidthWrapper = ({
  width = 1280,
  children,
  container,
  justify,
  alignItems,
  spacing,
  padding,
  centerSelf,
}: {
  width?: number;
  children: React.ReactNode;
  container?: boolean;
  padding?: boolean;
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  alignItems?: "center" | "stretch" | "flex-end" | "flex-start" | "baseline";
  centerSelf?: boolean;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}) => {
  return (
    <>
      {container && (
        <GridWrapper
          container={container}
          justify={justify}
          width={width}
          alignItems={alignItems}
          spacing={spacing}
          centerSelf
        >
          {children}
        </GridWrapper>
      )}
      {padding && <PaddingWrapper width={width}>{children}</PaddingWrapper>}
      {!container && !padding && (
        <WidthWrapper width={width}>{children}</WidthWrapper>
      )}
    </>
  );
};

const GridWrapper = styled(Grid)<{
  width: number;
  centerSelf?: boolean;
}>(
  ({ theme, width, centerSelf }) => `
    width: 100%;
    max-width: ${width}px;
    padding-left: 0;
    padding-right: 0;
    margin: ${centerSelf ? "0 auto" : "none"};
`
);
const PaddingWrapper = styled.div<{
  width: number;
}>(
  ({ theme, width }) => `
    width: 100%;
    max-width: ${width}px;
    margin: 0 auto;
    padding: ${theme.spacing(0, 3, 5)};
  ${theme.breakpoints.up("md")} {
    padding: ${theme.spacing(0, 7, 10)};
  }
  ${theme.breakpoints.up("lg")} {
    padding:${theme.spacing(0, 6, 10)};
  }
`
);
const WidthWrapper = styled.div<{
  width: number;
}>(
  ({ theme, width }) => `
    width: 100%;
    max-width: ${width}px;
    margin: 0 auto ${theme.spacing(5)}px;
    ${theme.breakpoints.up("md")}{
      margin: 0 auto ${theme.spacing(10)}px;
    }
`
);

export default ContentWidthWrapper;
