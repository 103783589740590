import { createMuiTheme, responsiveFontSizes, Theme } from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

const designColors = {
  primary: {
    main: "#222222",
  },
  secondary: {
    main: "#eee9e3", //dust
  },
  background: {
    dust: "#eee9e3",
    sandal: "#c3bbb2",
    sage: "#a1b2ae",
    blue: "#61799a",
    lightblue: "#eceff2",
    stone: "#e1dcd5",
    moss: "#aabfae",
    mud: "#c4bca6",
    lightmoss: "#ecf0ec",
  },
  tertiary: {
    orange: "#efa25d",
  },
  typography: {
    dark: "#222222",
    light: "#fffff5",
  },
  error: "#db564d",
  white: "#ffffff",
  black: "#222222",
  divider: "#707070",
  carcoal: "#212529"
};

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: designColors.primary.main,
      contrastText: designColors.white,
    },
    secondary: {
      main: designColors.secondary.main,
    },
    error: {
      main: designColors.error,
    },
    info: {
      main: designColors.tertiary.orange,
    },
    text: {
      primary: designColors.typography.dark,
      secondary: designColors.typography.light,
    },
    dust: { main: designColors.background.dust },
    sandal: { main: designColors.background.sandal },
    sage: { main: designColors.background.sage },
    blue: { main: designColors.background.blue },
    lightblue: { main: designColors.background.lightblue },
    stone: { main: designColors.background.stone },
    moss: { main: designColors.background.moss },
    lightmoss: { main: designColors.background.lightmoss },
    mud: { main: designColors.background.mud },
    background: {
      default: designColors.typography.light,
    },
    black: {
      main: designColors.black,
    },
    white: {
      main: designColors.white,
    },
    divider: designColors.divider,
    carcoal: designColors.carcoal,
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "48px",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "32px",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: 1.33,
    },
    h4: {
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: 1.33,
    },
    h5: {
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "uppercase",
      lineHeight: 1.5,
    },
    h6: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: 1.33,
    },
    subtitle1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: 1.71,
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "12px",
      lineHeight: 1.33,
      fontWeight: 400,
    },
    button: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: 1.5,
      textTransform: "none",
    },
    overline: {
      fontSize: "12px",
      lineHeight: 1.33,
    },
    caption: {
      fontSize: "12px",
      fontWeight: 700,
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "1rem",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        boxShadow: "none",
        fontWeight: 400,
        padding: "8px 32px",
        background: "white",
      },
      contained: {
        padding: "16px 32px",
      },
      outlined: {
        padding: "8px 32px",
      },
      sizeLarge: {
        padding: "8px 40px",
      },
      sizeSmall: {
        padding: "8px 16px",
        fontSize: "16px",
      },
    },
  },
};

export const theme: Theme = responsiveFontSizes(createMuiTheme(themeOptions));

export default theme;
