import styled from "styled-components";
import { Link } from "gatsby";

const NavLink = styled(Link)(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  font-weight: normal;
  font-size: 1rem;
  padding: ${theme.spacing(1)}px 0;
  line-height: 1;
  text-decoration: none;
  ${theme.breakpoints.up("md")} {
  margin-right: 1.5rem;
  }
  ${theme.breakpoints.up("lg")} {
  margin-right: 2rem;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &.active {
    border-bottom: 1px solid #979797;
    font-weight: bold;
  }
  &:hover {
    color: ${theme.palette.black.main} !important; 
  }
`
);

export default NavLink;
