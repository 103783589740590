import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import MobileNavigation from "./MobileNavigation";
import Navigation from "./Navigation";
import { ContentfulNavigationItem, Maybe } from "types/graphql-types";

export const navigationEntries = [
  { name: "Über uns", path: "/about-us/" },
  { name: "Presse & News", path: "/news/" },
  { name: "Kontakt", path: "/contact/" },
  { name: "Logistik", path: "/logistik/" },
];

interface Props {
  navigationIsDark: boolean;
  contentfulHeader: any;
}

export type LinkEntry =
  | Maybe<Pick<ContentfulNavigationItem, "id" | "label" | "url" | "type">>[]
  | null
  | undefined;

const Header = ({ navigationIsDark, contentfulHeader }: Props) => {
  if (!contentfulHeader) {
    throw new Error("Not all Contentful requirements met: contentfulHeader");
  }

  const [isScrolledUp, setIsScrolledUp] = useState<boolean>(false);
  const [scrolledToTop, setScrolledToTop] = useState<boolean>(true);
  let previousScroll = 0;

  const handelScrollBehavior = useCallback(() => {
    let currentScroll = window.scrollY;

    if (currentScroll === 0) {
      setScrolledToTop(true);
      setIsScrolledUp(false);
    }

    if (currentScroll <= 250) return null;

    let isDownScroll = currentScroll > previousScroll;
    if (isDownScroll) {
      setIsScrolledUp(false);
    } else if (!isDownScroll) {
      setIsScrolledUp(true);
      setScrolledToTop(false);
    }

    // TODO: remove eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
    previousScroll = currentScroll;
  }, [previousScroll]);

  useEffect(() => {
    window.addEventListener("scroll", handelScrollBehavior);
    return () => {
      window.removeEventListener("scroll", handelScrollBehavior);
    };
  }, [handelScrollBehavior]);

  return (
    <>
      {scrolledToTop ? (
        <Root className={"active"} navigationIsDark={navigationIsDark}>
          <Navigation links={contentfulHeader?.links} />
          <MobileNavigation links={contentfulHeader?.links} />
        </Root>
      ) : (
        <Transition>
          <Root
            isFixed={isScrolledUp}
            className={isScrolledUp ? "active" : "hidden"}
            navigationIsDark={navigationIsDark}
          >
            <Navigation links={contentfulHeader?.links} />
            <MobileNavigation links={contentfulHeader?.links} />
          </Root>
        </Transition>
      )}
    </>
  );
};

export default Header;

const Transition = styled.div`
  margin: 0 auto;
  .active {
    visibility: visible;
    transition: all 200ms ease-in;
  }
  .hidden {
    visibility: hidden;
    transition: all 200ms ease-out;
    transform: translate(0, -100%);
  }
`;

const Root = styled("header")<{ isFixed?: boolean; navigationIsDark: boolean }>(
  ({ isFixed, navigationIsDark, theme }) => `
  position: ${isFixed ? "fixed" : "absolute"};
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100vw;
  align-items: center;
  background-color: ${
    navigationIsDark ? theme.palette.dust.main : theme.palette.white.main
  };
  padding: ${theme.spacing(2, 3)};
  z-index: ${theme.zIndex.appBar};
  ${theme.breakpoints.up("sm")}{
    padding: ${theme.spacing(5, 7)};
  }
  ${theme.breakpoints.up("lg")}{
    justify-content: center;
    padding: ${theme.spacing(5, 6)};
  }

  & div {
    max-width: 1280px;
  }
`
);
