import { createGlobalStyle } from "styled-components";
import theme from "src/constants/theme";

const GlobalStyle = createGlobalStyle`
  html {
  font-family: ${theme.typography.fontFamily};
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
body > div,
#gatsby-focus-wrapper {
  height: 100%;
  background-color: ${theme.palette.primary.contrastText}
}
`;

export default GlobalStyle;
