import React, { useState } from "react";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import Dropdown from "react-bootstrap/Dropdown"
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
} from "@material-ui/icons";

const Language = ({ contentfulFooter }) => {
  let languagesUsed = contentfulFooter?.childContentfulFooterLanguagesListJsonNode
  let flagsUsed = {
    en: `${contentfulFooter?.enEnglishFlag?.file?.url}`,
    de: `${contentfulFooter?.deGermanFlag?.file?.url}`,
  }
  let flagNames = {
    en: `${contentfulFooter?.enEnglishFlag?.file?.fileName}`,
    de: `${contentfulFooter?.deGermanFlag?.file?.fileName}`,
  }
  const [isLangListVisible, setLangListVisible] = useState(false);
  return (
    <div>
      <Dropdown onClick={() => setLangListVisible(!isLangListVisible)}>
        <Dropdown.Toggle
        variant="switcher"
        id="dropdown-basic"
        >
        <div style={{display:"flex", justifyContent:"space-between"}}>
            <img src={contentfulFooter?.chosenFlag?.file?.url} alt={contentfulFooter?.chosenFlag?.file?.url}></img>
            <span style={{marginLeft:"1rem"}}>{contentfulFooter?.chosenLang}</span>
        </div>
        <span>{!isLangListVisible ? (
            <ArrowDownIcon height={16} />
          ) : (
            <ArrowUpIcon height={16} />
          )}</span>
        </Dropdown.Toggle>
      <Dropdown.Menu>
      {/* context component to get plugin configuration on the component level. */}
        <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
        //pass your languages array in gatsby-config.js file into a map
          languages.map(language => (
            <Dropdown.Item
              key={language}
        //pass the language clicked on to changeLocale so we can switch to
        //the chosen language
              onClick={() => {
                changeLocale(language)}}
              style={{
                width:'100%',
              }}
            >
            <img src={flagsUsed[language]} alt={flagNames[language]}></img>
            <span style={{marginLeft:"1rem"}}>{languagesUsed[language]}</span>
            </Dropdown.Item>
          ))
        }
        </IntlContextConsumer>
      </Dropdown.Menu>

      </Dropdown>
    </div>
  )
}

export default Language
