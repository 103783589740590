// todo: issue prevents usage: https://github.com/gatsbyjs/gatsby/issues/20696

// export default () => {};

import { graphql, useStaticQuery } from "gatsby";
import { ContentfulImageQuery } from "types/graphql-types";
import { FluidObject } from "gatsby-image";

// https://github.com/contentful/rich-text/issues/70#issuecomment-562997189

// TODO: currently maxWidth is always 1080.
// For client performance reasons we could create variations of `useContentfulImage` that query different widths.
// This will slow down the build though.
export default (assetUrl: string): FluidObject => {
  const { allContentfulAsset }: ContentfulImageQuery = useStaticQuery(
    graphql`
      query ContentfulImage {
        allContentfulAsset {
          nodes {
            file {
              url
            }
            fluid(maxWidth: 1080, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1080, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );
  return allContentfulAsset.nodes.find((n) => n!.file!.url! === assetUrl)!
    .localFile?.childImageSharp?.fluid! as FluidObject;
};
