import React from "react";
import {
  BLOCKS,
  INLINES,
  Document,
  Block,
  Inline,
} from "@contentful/rich-text-types";
import {
  documentToReactComponents,
  NodeRenderer,
} from "@contentful/rich-text-react-renderer";
import { Link } from "gatsby";
import { Typography } from "@material-ui/core";
import ContentfulImage from "./ContentfulImage";
import Initial from "./Initial";
import styled from "styled-components";

const nodeHasFieldsProxy = (Renderer: NodeRenderer) => (
  node: Block | Inline,
  children: React.ReactNode
) =>
  node.data.target.fields ? (
    Renderer(node, children)
  ) : (
    <>
      <Typography variant="body1"># Error: Node has no Fields #</Typography>
      {console.warn("# Error: Node has no Fields #", node)}
    </>
  );

const ParagraphRenderer = (node: Block | Inline, children: React.ReactNode) => (
  <Typography variant="body1" gutterBottom style={{ whiteSpace: "pre-wrap" }}>
    {children}
  </Typography>
);

const HeadingRenderer = (
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6",
  component: "h2" | "h3" | "h4" | "h5" | "h6" | "p"
) => (node: Block | Inline, children: React.ReactNode) => (
  <Typography variant={variant} component={component}>
    {children}
  </Typography>
);

const EmbeddedAssetRenderer = (
  node: Block | Inline,
  children: React.ReactNode
) => {
  return (
    <ContentfulImage
      title={node.data.target.fields.title["de"]}
      url={node.data.target.fields.file["de"].url}
      width={500}
    />
  );
};

const RichTextLink = styled.a`
  color: ${(props) => props.theme.palette.black.main};
  font-weight: 500;

  &:visited {
    text-decoration: "none";
    color: ${(props) => props.theme.palette.black.main};
  }
`;

const HyperlinkRenderer = (node: Block | Inline, children: React.ReactNode) => {
  return (
    <RichTextLink
      href={node.data.uri}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </RichTextLink>
  );
};

const HyperlinkEntryRenderer = (entryLinkPrefix: string) => (
  node: Block | Inline,
  children: React.ReactNode
) => {
  if (!node.data.target.fields)
    throw new Error("Error: Hyperlink Entry didn't contain fields.");

  return (
    <Link to={`${entryLinkPrefix}/${node.data.target.fields.slug["de"]}`}>
      {children}
    </Link>
  );
};

const options = ({ entryLinkPrefix }: { entryLinkPrefix: string }) => ({
  renderMark: {
    // [MARKS.ITALIC]: (text: any) => <span style={{fontStyle: 'italic'}}>{text}</span>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: ParagraphRenderer,
    [BLOCKS.HEADING_1]: HeadingRenderer("h1", "h2"),
    [BLOCKS.HEADING_2]: HeadingRenderer("h2", "h2"),
    [BLOCKS.HEADING_3]: HeadingRenderer("h3", "h3"),
    [BLOCKS.HEADING_4]: HeadingRenderer("h4", "h4"),
    [BLOCKS.HEADING_5]: HeadingRenderer("h5", "h5"),
    [BLOCKS.HEADING_6]: HeadingRenderer("h6", "h6"),
    [BLOCKS.EMBEDDED_ASSET]: nodeHasFieldsProxy(EmbeddedAssetRenderer),
    [INLINES.HYPERLINK]: HyperlinkRenderer,
    [INLINES.ENTRY_HYPERLINK]: nodeHasFieldsProxy(
      HyperlinkEntryRenderer(entryLinkPrefix)
    ),
  },
});

const ContentfulRichText = ({
  /**
   * json document of the contentful richtext node (node { richtext { json} } )
   */
  json,
  /**
   * The Richtext json may contain references to Entries of the same ContentType. To resolve this link we will use the slug. This variable allows to set the corresponding url-prefix.
   * e.g.
   * slug = 'content-abc'
   * entryLinkPrefix = 'type'
   * resolved link => '/type/content-abc'
   */
  entryLinkPrefix = "",
  showInitial = false,
}: {
  json: Document;
  entryLinkPrefix?: string;
  showInitial?: boolean;
}) => {
  const richtext = documentToReactComponents(
    json,
    options({ entryLinkPrefix })
  );

  if (showInitial) {
    return <Initial>{richtext}</Initial>;
  }

  return <>{richtext}</>;
};

export default ContentfulRichText;
