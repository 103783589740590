import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
}

const Initial = ({ children }: Props) => {
  return <InitialStyled>{children}</InitialStyled>;
};

export default Initial;

const InitialStyled = styled.section`
  display: inline-block;
  & > p:nth-of-type(1)::first-letter {
    float: left;
    font-size: 62px;
    position: absolute;
    line-height: 62px;
    margin-right: 4px;
  }
  &::first-letter {
    float: left;
    font-size: 62px;
    position: absolute;
    line-height: 62px;
    margin-right: 4px;
  }
`;
