import React from "react";

import Image, { GatsbyImageProps } from "gatsby-image";
import useContentfulImage from "src/hooks/useContentfulImage";

import styled from "styled-components";

interface ContentfulImageContainerProps {
  width?: number | string;
  height?: number | string;
}
const ImageContainer = styled("div")<ContentfulImageContainerProps>(
  ({ width, height }) => `
  width: ${width ? `${width}px` : "auto"};
  height: ${height ? `${height}px` : "auto"};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
);

interface ContentfulImageProps
  extends ContentfulImageContainerProps,
    GatsbyImageProps {
  url: string;
}
const ContentfulImage = ({
  url,
  width,
  height,
  fluid,
  ...imageProps
}: ContentfulImageProps) => {
  const contentfulFluid = useContentfulImage(url);
  return (
    <ImageContainer width={width} height={height}>
      <>
        <Image
          fluid={contentfulFluid}
          {...imageProps}
          style={{ height: "100%", width: "100%" }}
        />
      </>
    </ImageContainer>
  );
};

export default ContentfulImage;
