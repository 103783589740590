import React, { useState } from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
} from "@material-ui/icons";
import styled from "styled-components";
import theme from "src/constants/theme";
import Language from "../../language";
import { LinkEntry } from "./index";
import SocialIcon from "./SocialIcon";

interface Props {
  content: any;
  renderLinks: any;
}

const MobileLinks = ({ content, renderLinks }: Props) => {
  const [isFirstListVisible, setFirstListVisible] = useState(false);
  const [isSecondListVisible, setSecondListVisible] = useState(false);
  const [isThirdListVisible, setThirdListVisible] = useState(false);

  return (
    <BottomMobile direction="row" container justify="space-between">
      <GridItem item xs={12}>
        <SubHeadline onClick={() => setFirstListVisible(!isFirstListVisible)}>
          <Subtitle variant="h5" gutterBottom>
            {content.columnOneTitle}
          </Subtitle>
          {!isFirstListVisible ? (
            <ArrowDownIcon height={16} />
          ) : (
            <ArrowUpIcon height={16} />
          )}
        </SubHeadline>
        {isFirstListVisible && (
          <LinksStyled>
            {renderLinks(content.columnOneLinks! as LinkEntry[])}
          </LinksStyled>
        )}
      </GridItem>
      <GridItem item xs={12}>
        <SubHeadline onClick={() => setSecondListVisible(!isSecondListVisible)}>
          <Typography variant="h5" gutterBottom>
            {content.columnTwoTitle}
          </Typography>
          {!isSecondListVisible ? (
            <ArrowDownIcon height={16} />
          ) : (
            <ArrowUpIcon height={16} />
          )}
        </SubHeadline>
        {isSecondListVisible && (
          <LinksStyled>
            {renderLinks(content.columnTwoLinks! as LinkEntry[])}
          </LinksStyled>
        )}
      </GridItem>
      <GridItem item xs={12}>
        <SubHeadline onClick={() => setThirdListVisible(!isThirdListVisible)}>
          <Typography variant="h5" gutterBottom>
            {content.columnThreeTitle}
          </Typography>
          {!isThirdListVisible ? (
            <ArrowDownIcon height={16} />
          ) : (
            <ArrowUpIcon height={16} />
          )}
        </SubHeadline>
        {isThirdListVisible && (
          <LinksStyled>
            {renderLinks(content.columnThreeLinks! as LinkEntry[])}
          </LinksStyled>
        )}
      </GridItem>
      <GridItem item xs={12}>
        <Typography variant="h5" gutterBottom>
          {content?.columnFourTitle}
        </Typography>
        <Language
          contentfulFooter={content}
        />
      </GridItem>
      <Bottom>
        <div>
          {content?.instagramLink && (
            <IconButton
              style={{ flexShrink: 1 }}
              href={content?.instagramLink}
              disableRipple
            >
              <SocialIcon type="instagram" big />
            </IconButton>
          )}
          {content?.facebookLink && (
            <IconButton
              style={{ flexShrink: 1 }}
              href={content?.facebookLink}
              disableRipple
            >
              <SocialIcon type="facebook" big />
            </IconButton>
          )}
          {content?.pinterestLink && (
            <SocialLink
              target="_blank"
              href={content?.pinterestLink}
              disableRipple
              style={{ flexShrink: 1 }}
            >
              <SocialIcon type="pinterest" />
            </SocialLink>
          )}
        </div>
        <Copyright variant="body2">{content.copyright}</Copyright>
      </Bottom>
    </BottomMobile>
  );
};

const SocialLink = styled(IconButton) <{ href: string; target: string }>`
  padding: ${theme.spacing(1)}px;
  margin-top: -${theme.spacing(1)}px;
`;

export default MobileLinks;

const LinksStyled = styled.div`
  padding-bottom: ${theme.spacing(3)}px;
`;

const Copyright = styled(Typography)`
  font-size: 10px;
  font-weight: 500;
`;

const BottomMobile = styled(Grid)``;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const GridItem = styled(Grid)`
  margin-bottom: ${theme.spacing(3)}px;
  border-bottom: 1px solid #979797;
  ${theme.breakpoints.up("md")} {
    margin-bottom: ${theme.spacing(5)}px;
    border-bottom: none;
  }
`;

const Subtitle = styled(Typography)`
  display: inline-block;
`;

const SubHeadline = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: ${theme.spacing(1)}px;
`;
