/* eslint-disable react/style-prop-object */
import React from "react";
import { Link } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import theme from "src/constants/theme";

const width = 362;
const height = 36;

const Logo = ({ small }: { small: boolean }) => (
  <svg
    width={small ? width / 2 : width}
    height={small ? height / 2 : height}
    viewBox="0 0 1957 195"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill="#231f20"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path d="M13.363,98.944c0,9.116 3.244,16.532 9.66,22.016c6.718,5.944 15.985,8.886 27.807,8.886c3.862,0 8.034,-0.617 12.515,-1.78c5.329,-1.387 8.498,-3.244 9.501,-5.484l0,-10.735c0,-3.636 -0.849,-6.108 -2.395,-7.419c-1.622,-1.313 -4.866,-2.161 -9.656,-2.547l-3.089,0c-0.309,-0.154 -0.388,-0.463 -0.388,-0.697c0,-0.305 0.154,-0.615 0.542,-0.849l34.687,0c0.46,0.234 0.769,0.465 0.769,0.773c0,0.31 -0.075,0.619 -0.309,0.773c-5.485,0.386 -8.264,2.547 -8.264,6.33l0,16.536c-10.354,5.099 -22.711,7.649 -36.927,7.649c-13.439,0 -24.642,-2.942 -33.679,-8.886c-9.423,-6.186 -14.137,-14.364 -14.137,-24.332c0,-10.35 4.865,-18.773 14.676,-25.106c9.35,-6.104 20.859,-9.196 34.686,-9.196c4.945,0 10.505,0.543 16.763,1.471c7.646,1.158 12.824,2.55 15.529,4.247l1.621,-0.385l1.08,0l-1.777,12.98c-0.306,0.385 -0.774,0.539 -1.158,0.614c-0.385,0 -0.773,-0.15 -1.083,-0.535c-2.085,-5.103 -5.793,-9.041 -11.123,-11.746c-5.33,-2.704 -11.663,-4.093 -18.848,-4.093c-11.972,0 -21.244,3.09 -27.731,9.193c-6.182,5.635 -9.272,13.129 -9.272,22.322Z" />
      <path d="M143.219,98.944l16.762,0c16.842,0.157 25.265,-5.021 25.265,-15.601c0,-10.12 -8.035,-15.14 -24.182,-15.065l-17.845,0l0,30.666Zm0,2.164l0,17.076c0,3.62 0.694,6.254 2.007,7.799c1.626,1.856 4.715,2.928 9.271,3.321l0.544,0.693l-0.853,0.77l-32.906,0l-0.849,-0.77l0.539,-0.693c4.402,-0.393 7.416,-1.54 8.963,-3.396c1.388,-1.63 2.006,-4.179 2.006,-7.724l0,-39.013c0,-3.552 -0.539,-6.027 -1.546,-7.57c-1.468,-1.935 -4.248,-3.168 -8.495,-3.633l-0.543,-0.693l0.852,-0.774l15.371,-0.384l22.79,-0.155c9.731,-0.075 17.921,0.845 24.487,2.935c9.192,2.858 13.828,7.491 13.828,13.903c0,8.962 -8.114,14.447 -24.257,16.535c5.638,1.544 10.195,3.551 13.673,6.254c2.316,1.698 5.1,4.629 8.419,8.574c3.398,4.247 6.182,7.189 8.268,8.879c5.329,4.255 10.889,6.496 16.762,6.804c0.155,0.151 0.309,0.384 0.309,0.619c0,0.386 -0.154,0.694 -0.309,0.921l-7.495,0c-0.924,0 -1.147,0.011 -5.596,-0.686c-5.418,-0.851 -5.991,-1.162 -9.001,-2.015c-3.553,-0.921 -7.031,-2.777 -10.275,-5.643c-1.546,-2.156 -3.937,-5.402 -7.106,-9.807c-5.409,-7.107 -12.67,-11.199 -21.862,-12.127l-16.996,0Z" />
      <path d="M270.214,118.184c0,3.395 0.54,5.869 1.468,7.408c1.392,2.006 4.021,3.244 8.034,3.712l0.465,0.693l-0.695,0.77l-29.353,0l-0.622,-0.77l0.389,-0.693c3.941,-0.468 6.487,-1.706 7.724,-3.712c0.928,-1.464 1.391,-3.939 1.391,-7.408l0,-39.013c0,-3.477 -0.543,-5.948 -1.47,-7.416c-1.388,-2.089 -4.013,-3.322 -8.034,-3.787l-0.46,-0.693l0.694,-0.774l29.357,0l0.614,0.774l-0.384,0.693c-3.942,0.465 -6.488,1.698 -7.726,3.787c-0.927,1.389 -1.392,3.864 -1.392,7.416l0,39.013Z" />
      <path d="M308.839,130.767l-0.924,-0.77l0.46,-0.693c5.1,-0.393 8.578,-1.389 10.354,-3.018c1.777,-1.539 2.705,-4.24 2.705,-8.102l0,-39.013c0,-3.631 -0.544,-6.102 -1.622,-7.57c-1.471,-1.935 -4.251,-3.168 -8.423,-3.633l-0.539,-0.693l0.773,-0.774l68.286,0l2.856,13.44c-0.23,0.234 -0.536,0.309 -1.004,0.389c-0.309,0 -0.694,0 -1.003,-0.08l-0.539,-0.619c-3.09,-6.637 -10.275,-9.965 -21.633,-9.965l-24.562,0c-0.928,0 -1.392,0.08 -1.392,0.159l0,25.566l20.24,0c10.43,0 16.608,-2.392 18.464,-7.183c0.385,-0.308 0.693,-0.46 1.079,-0.539l1.237,0.079l0,18.694l-2.165,-0.314c-2.083,-5.02 -8.265,-7.569 -18.615,-7.569l-20.24,0l0,20.394c0,3.093 0.619,5.251 1.852,6.413c1.471,1.388 4.172,2.157 8.035,2.157l16.223,0c13.597,0 22.71,-3.622 27.345,-10.803l0.54,-0.467c1.237,0 1.932,0.074 2.011,0.385l-3.784,13.978l-76.015,0.151Z" />
      <path d="M484.579,113.009c0,6.336 -3.783,11.269 -11.353,14.755c-6.412,3.087 -13.982,4.632 -22.789,4.632c-8.884,0 -18.385,-1.704 -28.505,-5.024l-1.468,0.618l-1.157,0l0.46,-13.518c0.388,-0.151 0.777,-0.151 1.237,-0.151c0.543,0 0.928,0.076 1.237,0.377c1.079,4.723 4.482,8.502 10.275,11.285c5.251,2.476 11.278,3.789 17.921,3.789c6.103,0 11.356,-1.087 15.679,-3.093c5.099,-2.401 7.65,-5.793 7.65,-10.125c0,-6.563 -7.571,-11.658 -22.636,-15.37c-19.776,-4.865 -29.741,-11.278 -29.741,-19.308c0,-5.714 3.478,-10.044 10.354,-13.134c5.714,-2.549 12.515,-3.866 20.47,-3.866c8.419,0 16.762,1.471 25.03,4.33l1.313,-0.542l1.237,0l-0.698,11.277c-0.23,0.309 -0.539,0.464 -0.928,0.543c-0.229,0 -0.539,-0.234 -0.924,-0.543c-1.546,-4.014 -5.024,-7.182 -10.584,-9.502c-4.866,-2.161 -10.274,-3.164 -16.222,-3.164c-5.021,0 -9.268,0.928 -12.67,2.701c-3.784,2.089 -5.636,4.944 -5.636,8.652c0,6.028 8.494,11.123 25.415,15.216c18.076,4.251 27.033,10.663 27.033,19.165Z" />
      <path d="M586.779,79.171c0,-3.631 -0.54,-6.102 -1.622,-7.57c-1.388,-1.935 -4.248,-3.168 -8.42,-3.633l-0.542,-0.693l0.777,-0.774l15.446,-0.384l26.728,-0.155c14.371,-0.154 26.113,2.241 35.225,7.107c10.43,5.484 15.685,13.438 15.685,23.944c0,10.82 -5.255,19.314 -15.685,25.418c-9.347,5.559 -21.397,8.336 -36.149,8.336l-42.332,0l-0.774,-0.77l0.54,-0.693c4.481,-0.393 7.494,-1.54 9.116,-3.396c1.313,-1.63 2.007,-4.179 2.007,-7.724l0,-39.013Zm69.988,19.003c0,-20.239 -14.137,-30.28 -42.409,-29.896l-16.377,0l0,51.595c0,4.405 1.853,7.107 5.41,8.117c1.387,0.385 5.794,0.619 13.13,0.619c12.978,0 23.019,-2.626 29.896,-7.799c6.951,-5.176 10.35,-12.75 10.35,-22.636Z" />
      <path d="M700.336,130.767l-0.928,-0.77l0.542,-0.693c5.022,-0.393 8.495,-1.389 10.276,-3.018c1.773,-1.539 2.701,-4.24 2.701,-8.102l0,-39.013c0,-3.631 -0.539,-6.102 -1.623,-7.57c-1.467,-1.935 -4.247,-3.168 -8.418,-3.633l-0.543,-0.693l0.773,-0.774l68.29,0l2.856,13.44c-0.231,0.234 -0.54,0.309 -1.004,0.389c-0.31,0 -0.695,0 -1.003,-0.08l-0.54,-0.619c-3.093,-6.637 -10.275,-9.965 -21.552,-9.965l-24.646,0c-0.928,0 -1.388,0.08 -1.388,0.159l0,25.566l20.24,0c10.426,0 16.607,-2.392 18.46,-7.183c0.384,-0.308 0.697,-0.46 1.082,-0.539l1.233,0.079l0,18.694l-2.161,-0.314c-2.086,-5.02 -8.263,-7.569 -18.614,-7.569l-20.24,0l0,20.394c0,3.093 0.618,5.251 1.931,6.413c1.388,1.388 4.093,2.157 8.035,2.157l16.142,0c13.595,0 22.711,-3.622 27.347,-10.803l0.54,-0.467c1.237,0 1.931,0.074 2.01,0.385l-3.787,13.978l-76.011,0.151Z" />
      <path d="M825.245,98.249c0,8.729 3.4,16.072 10.276,22.017c7.181,6.413 16.377,9.58 27.73,9.58c15.838,0 27.037,-5.024 33.603,-14.906l3.245,3.863c-1.467,3.243 -3.013,5.944 -4.711,8.177l-1.467,-0.076c-8.653,3.636 -19.313,5.492 -31.983,5.492c-14.21,0 -25.878,-2.942 -35.07,-8.653c-10.041,-6.179 -15.064,-14.52 -15.064,-25.03c0,-10.428 5.177,-18.772 15.607,-25.026c9.576,-5.873 21.395,-8.811 35.455,-8.811c11.199,0 21.863,2.165 31.828,6.336l1.388,-0.308l1.083,0l-1.083,11.971c-0.384,0.314 -0.694,0.393 -0.924,0.393c-0.544,0 -1.083,-0.233 -1.468,-0.468c-5.103,-10.199 -15.453,-15.371 -31.132,-15.371c-12.051,0 -21.319,2.94 -27.965,8.733c-6.254,5.48 -9.348,12.82 -9.348,22.087Z" />
      <path d="M1031.04,98.635c-0.16,10.195 -5.101,18.463 -15.07,24.8c-9.34,5.946 -20.85,8.961 -34.526,8.961c-13.752,0 -25.185,-3.015 -34.298,-8.961c-9.581,-6.261 -14.446,-14.53 -14.446,-24.8c0,-10.196 4.942,-18.46 14.906,-24.798c9.351,-5.943 20.938,-8.961 34.687,-8.961c13.595,0 25.037,3.018 34.217,8.961c9.73,6.413 14.61,14.682 14.53,24.798Zm-13.6,0c-0.16,-8.959 -3.32,-16.22 -9.5,-21.938c-6.57,-6.178 -15.604,-9.268 -27.195,-9.268c-11.352,0 -20.16,3.165 -26.338,9.344c-5.638,5.642 -8.344,12.903 -8.188,21.862c0.229,8.956 3.398,16.305 9.501,21.94c6.642,6.179 15.679,9.271 27.191,9.271c11.439,0 20.319,-3.092 26.499,-9.353c5.56,-5.629 8.26,-12.902 8.03,-21.858Z" />
      <path d="M1139.34,98.249c0,8.729 3.48,16.072 10.35,22.017c7.11,6.413 16.38,9.58 27.66,9.58c15.83,0 27.03,-5.024 33.6,-14.906l3.25,3.863c-1.4,3.243 -3.02,5.944 -4.72,8.177l-1.47,-0.076c-8.65,3.636 -19.31,5.492 -31.98,5.492c-14.21,0 -25.88,-2.942 -35.07,-8.653c-10.04,-6.179 -14.98,-14.52 -14.98,-25.03c0,-10.428 5.17,-18.772 15.53,-25.026c9.65,-5.873 21.47,-8.811 35.45,-8.811c11.28,0 21.86,2.165 31.91,6.336l1.31,-0.308l1.08,0l-1.08,11.971c-0.39,0.314 -0.7,0.393 -0.93,0.393c-0.54,0 -1,-0.233 -1.47,-0.468c-5.09,-10.199 -15.44,-15.371 -31.05,-15.371c-12.05,0 -21.4,2.94 -28.04,8.733c-6.26,5.48 -9.35,12.82 -9.35,22.087Z" />
      <path d="M1345.13,98.635c-0.08,10.195 -5.1,18.463 -14.98,24.8c-9.43,5.946 -20.94,8.961 -34.61,8.961c-13.76,0 -25.19,-3.015 -34.22,-8.961c-9.66,-6.261 -14.53,-14.53 -14.53,-24.8c0,-10.196 5.02,-18.46 14.91,-24.798c9.35,-5.943 20.94,-8.961 34.69,-8.961c13.67,0 25.1,3.018 34.29,8.961c9.74,6.413 14.53,14.682 14.45,24.798Zm-13.52,0c-0.23,-8.959 -3.39,-16.22 -9.5,-21.938c-6.64,-6.178 -15.68,-9.268 -27.19,-9.268c-11.43,0 -20.24,3.165 -26.42,9.344c-5.64,5.642 -8.34,12.903 -8.19,21.862c0.24,8.956 3.4,16.305 9.58,21.94c6.57,6.179 15.61,9.271 27.12,9.271c11.43,0 20.31,-3.092 26.57,-9.353c5.48,-5.629 8.19,-12.902 8.03,-21.858Z" />
      <path d="M1470.74,66.657l12.59,0l0.46,0.693l-0.23,0.694c-5.02,0.853 -7.34,4.557 -6.95,11.204l2.16,38.936c0.15,3.62 0.7,6.17 1.7,7.484c1.47,1.93 4.17,3.168 8.35,3.636l0.53,0.693l-0.84,0.77l-30.37,0l-0.69,-0.77l0.38,-0.693c4.1,-0.468 6.88,-1.706 8.2,-3.636c1.15,-1.54 1.62,-4.089 1.46,-7.484l-2.16,-43.648l-34.84,55.611c-0.61,0.393 -1.24,0.62 -1.85,0.62c-0.62,0 -1.24,-0.227 -1.86,-0.62l-34.92,-55.69l-2,43.727c-0.31,6.563 2.85,10.275 9.5,11.12l0.39,0.693l-0.86,0.77l-24.1,0l-0.77,-0.77l0.54,-0.693c3.94,-0.468 6.64,-1.706 8.19,-3.863c1.16,-1.54 1.78,-3.939 2.01,-7.257l2.01,-40.868c0.07,-2.705 -0.23,-4.715 -0.93,-6.027c-1.08,-1.698 -3.17,-2.777 -6.33,-3.245l-0.39,-0.694l0.69,-0.693l19.01,0l33.45,51.678l32.9,-51.603l1.09,-0.075l4.48,0Z" />
      <path d="M1545.18,101.727l0,16.457c0,3.787 0.85,6.487 2.55,8.102c1.7,1.629 5.02,2.625 9.89,3.018l0.54,0.693l-0.78,0.77l-33.05,0l-0.86,-0.77l0.39,-0.693c4.25,-0.393 7.03,-1.54 8.5,-3.554c1,-1.472 1.54,-4.021 1.54,-7.566l0,-39.013c0,-3.631 -0.54,-6.177 -1.54,-7.649c-1.47,-1.931 -4.25,-3.168 -8.5,-3.554l-0.39,-0.693l0.86,-0.774l15.21,-0.384l22.09,-0.155c9.43,-0.075 17.46,1.004 24.26,3.244c8.96,2.934 13.36,7.567 13.36,13.903c0,6.182 -3.7,10.893 -11.12,14.213c-8.65,3.862 -18.69,5.484 -30.13,4.945l-12.82,-0.54Zm0,-2.549c3.09,0.539 6.64,0.77 10.58,0.77c8.27,0 15.14,-1.155 20.47,-3.55c6.57,-2.856 9.81,-7.186 9.81,-13.13c0,-10.818 -9.73,-15.918 -29.19,-15.224l-11.67,0.389l0,30.745Z" />
      <path d="M1713.76,129.304l0.23,0.693l-0.46,0.77l-31.9,0l-0.55,-0.77l0.31,-0.693c5.65,-0.31 8.66,-1.314 8.81,-3.018l0.15,-1.312c0.08,-1.313 -0.46,-2.928 -1.54,-5.017l-10.59,-19.003l-30.59,0l-9.27,16.37c-1.69,3.017 -2.55,5.258 -2.55,6.729c0,2.927 3.17,4.632 9.51,5.251l0.31,0.693l-0.55,0.77l-26.49,0l-0.54,-0.77l0.23,-0.693c5.49,-0.845 10.2,-4.791 14.37,-11.897l28.19,-48.359l0.55,-2.777l0.69,-0.928c1,-0.233 1.93,-0.308 2.78,-0.308l0.85,0c1.24,0 2.47,0.075 3.71,0.308l1.93,2.625l29.97,52.449c3.17,5.643 7.34,8.66 12.44,8.887Zm-37.08,-31.749l-13.6,-23.249l-13.59,23.249l27.19,0Z" />
      <path d="M1823.38,116.327l0,-37.156c0,-3.712 -0.7,-6.337 -2.17,-7.882c-1.54,-1.774 -4.71,-2.935 -9.35,-3.321l-0.53,-0.693l0.92,-0.774l26.81,0l0.77,0.774l-0.55,0.693c-4.32,0.386 -7.25,1.623 -8.8,3.554c-1.24,1.472 -1.85,4.018 -1.85,7.649l0,51.914c-0.31,0.616 -0.7,0.844 -1.39,0.844c-0.55,0 -1.32,-0.228 -2.4,-0.844l-8.34,-7.032l-61.56,-49.517l0,43.648c0,3.62 0.69,6.254 2,7.799c1.63,1.856 4.71,2.928 9.28,3.321l0.53,0.693l-0.93,0.77l-26.49,0l-1,-0.77l0.54,-0.693c4.4,-0.393 7.42,-1.54 9.03,-3.396c1.25,-1.63 1.85,-4.179 1.85,-7.724l0,-44.347l-1.77,-1.697c-1.39,-1.851 -4.09,-3.243 -8.03,-4.172l-0.39,-0.693l0.7,-0.774l20.7,0c13.98,11.048 34.76,27.577 62.42,49.826Z" />
      <path d="M1929.36,67.968l-0.31,-0.693l0.54,-0.774l26.04,0l0.45,0.774l-0.22,0.693c-3.25,0.619 -6.1,1.698 -8.73,3.321c-3.94,2.548 -7.11,5.099 -9.5,7.728l-22.33,25.101l0,14.066c0,3.62 0.54,6.17 1.63,7.566c1.46,2.014 4.39,3.161 8.65,3.554l0.53,0.693l-0.77,0.77l-31.75,0l-0.77,-0.77l0.46,-0.693c4.41,-0.393 7.34,-1.54 8.89,-3.554c1.24,-1.472 1.86,-4.021 1.86,-7.566l0,-13.054l-25.42,-28.199c-4.25,-4.715 -9.43,-7.649 -15.53,-8.963l-0.31,-0.693l0.54,-0.774l31.37,0l0.53,0.774l-0.3,0.693c-4.09,0.235 -6.42,1.158 -6.95,2.626l-0.16,0.618c0,1.313 1.24,3.169 3.71,5.794l21.08,22.711l19.63,-21.473c2.47,-2.704 3.71,-5.021 3.71,-6.797c0,-1.856 -2.24,-3.014 -6.57,-3.479Z" />
      <rect x="463.491" y="0" width="1043.78" height="3.86" />
      <rect x="463.491" y="190.313" width="1043.78" height="3.86" />
    </g>
  </svg>
);

const BrandLogo = () => {
  const desktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Link to="/">
      <Logo small={!desktop} />
    </Link>
  );
};

export default BrandLogo;
