import React from "react";
import { Container, CssBaseline, MuiThemeProvider } from "@material-ui/core";

import styled, { ThemeProvider } from "styled-components";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

import Footer from "src/common/Footer";
import theme from "src/constants/theme";
import GlobalStyle from "../components/GobalStyles";
import Header from "../Header";

type Props = {
  children?: React.ReactNode;
  maxWidth?: Breakpoint;
  stageContent?: React.ReactNode;
  stageIsSmall?: boolean;
  navigationIsDark?: boolean;
  contentfulFooter: any,
  contentfulHeader: any,
};

export default function Layout({
  contentfulFooter,
  contentfulHeader,
  children,
  maxWidth = "md",
  stageContent,
  navigationIsDark = false,
}: Props) {
  // register smooth scrolling for links to elements
  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]');
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <Root>
          <Header
            contentfulHeader={contentfulHeader}
            navigationIsDark={navigationIsDark} />
          <Body>
            {stageContent && <>{stageContent}</>}
            {children && <Content>{children}</Content>}
          </Body>
          <Footer
            contentfulFooter={contentfulFooter}
          />
        </Root>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

const Content = styled(Container)(
  ({ theme }) => `
    flex: 1 0 auto;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;

`
);

const Body = styled("div")(
  ({ theme }) => `
  position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    margin: 0 auto;
    background-color: ${theme.palette.primary.contrastText};
    width: 100%;

`
);

const Root = styled("div")(
  () => `
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.primary.contrastText};

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: auto;
  }
`
);
